<template>
  <b-row
    class="cws-container cws-sidebar-right mt-3 blog-wrapper"
  >
  <b-col md="8">
      <div>
        <h5 class="text-black">Contact Information:</h5>
        <p class="pr-5 text-black font-weight-bold" style="font-size: 0.8rem !important;">
         Email: info@thecophq.org<br />
         Telephone: (+233) 571 124 180 | 0570 947 621 <br />Helpline: (+233) 543 308 187<br />
         Address: Gbeshigon Street, La, Accra, Ghana<br />
         (Near Maale Dada Street) <br />Digital Address: GL-020-5834
        </p>

        <div class="my-2 mt-1">
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="FacebookIcon" size="20" color="black"></feather-icon>
          </a>
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="TwitterIcon" size="20" color="black"></feather-icon>
          </a>
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="InstagramIcon" size="20" color="black"></feather-icon>
          </a>
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="YoutubeIcon" size="20" color="black"></feather-icon>
          </a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BOverlay,
  BAlert,
  BFormInput,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BButton,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import BlogDetails from "@/@core/components/shared/blogs/BlogDetails.vue";

export default {
  components: {
    BOverlay,
    BAlert,
    BFormInput,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    BCardText,
    ContentWithSidebar,

    BlogDetails,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      blogPost: {},
      recentBlogPosts: [],
      socialShareIcons: [
        "GithubIcon",
        "GitlabIcon",
        "FacebookIcon",
        "TwitterIcon",
        "LinkedinIcon",
      ],
      loading: false,
      recentsLoading: false,
    };
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
